(function ($) {
    window.recaptchaId = null;
    window.onUserVerified = function (token) {
        var form = $("#CfourItemQuestionsForm");
        form.find(".fa-spin").removeClass("removedSpinner");
        $.ajax({
            url: form.attr("action"),
            method: "POST",
            beforeSend: function () {
                form.find("input").prop("disable", true);
                form.find("button").prop("disable", true);
            },
            data: form.serialize() + '&g-recaptcha-response=' + token
        })
            .done(
                function (data) {
                    if ($(data).closest('.CfourItemQuestionsResponse').hasClass('error')) {
                        if (form.find('.CfourItemQuestionsResponse').length > 0) {
                            form.find('.CfourItemQuestionsResponse').replaceWith(data);
                            /*form.append(data);*/
                        } else {
                            form.append(data);
                        }
                    } else {
                        form.parent().html(data);
                    }
                }
            )
            .fail(
                function (jqXHR, textStatus) {
                    console.log("Etwas schief gelaufen: ", jqXHR, textStatus);
                }
            )
            .always(
                function (data) {
                    form.find(".fa-spin").addClass("removedSpinner");
                }
            );
    };
})(jQuery);
