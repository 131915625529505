Vue.component("cfour-item-questions", {

    delimiters: ["${", "}"],
    name: "cfour-item-questions",
    props: [
        "template"
    ],

    data() {
        return {
            recaptchaEnabled: false,
            privacyPolicyAccepted: false,
            privacyPolicyShowError: false,
            enableConfirmingPrivacyPolicy: App.config.contact.enableConfirmingPrivacyPolicy,
            honeypot: "",
            result: location.host

        };
    },
    computed: Vuex.mapState({
        currentVariation: state => state.item.variation
    }),
    created() {
        let _this = this;
        this.$options.template = this.template;

        this.recaptchaEnabled = false;
        // if (typeof window.grecaptcha === "undefined") {
        //     loadAsync('https://www.google.com/recaptcha/api.js', this.initRecaptcha)
        // } else {
        this.$nextTick(function () {

            if (typeof window.grecaptcha.render !== "function") {
                window.grecaptcha.ready = _this.initRecaptcha;
            } else {
                _this.initRecaptcha();
            }

            var url = document.location.toString();
            if (url.match('#')) {
                $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
            }
        });
        $(document).on('click', '[href="#cfourItemQuestions"]', function () {
            if (typeof window.recaptchaId === "undefined" || window.recaptchaId === null) {
                _this.initRecaptcha();
            }
        });
        // }

    },
    methods: {
        initRecaptcha: function () {
            // recaptcha init
            // this callback will be called by recaptcah/api.js once its loaded. If we used
            // render=explicit as param in script src, then we can explicitly render reCaptcha at this point

            // element to "render" invisible captcha in
            var htmlEl = document.querySelector('#CfourItemQuestionsForm .recaptcha');

            if (htmlEl) {
                // option to captcha
                var captchaOptions = {
                    sitekey: $(htmlEl).data('sitekey'),
                    size: 'invisible',
                    // tell reCaptcha which callback to notify when user is successfully verified.
                    // if this value is string, then it must be name of function accessible via window['nameOfFunc'],
                    // and passing string is equivalent to specifying data-callback='nameOfFunc', but it can be
                    // reference to an actual function
                    callback: window.onUserVerified
                };

                // Only for "invisible" type. if true, will read value from html-element's data-* attribute if its not passed via captchaOptions
                var inheritFromDataAttr = true;

                // now render
                window.recaptchaId = window.grecaptcha.render(htmlEl, captchaOptions, inheritFromDataAttr);

                this.recaptchaEnabled = true;
            }
        },
        onSubmit: function () {
            if (!this.enableConfirmingPrivacyPolicy || this.privacyPolicyAccepted)
            {

                if (typeof window.recaptchaId === "undefined") {
                    this.initRecaptcha();
                }
                if (this.recaptchaEnabled) {

                    var token = window.grecaptcha.getResponse(window.recaptchaId);
                    // if no token, mean user is not validated yet
                    if (!token) {
                        // trigger validation
                        window.grecaptcha.execute(window.recaptchaId);
                        return;
                    }

                } else {
                    window.onUserVerified();
                }
            }
            else
            {
                this.privacyPolicyShowError = true;
            }
        },
        getUserObject()
        {
            const userObject =
                {
                    honeypot: this.honeypot
                };

            return userObject;
        },
        privacyPolicyValueChanged(value)
        {
            this.privacyPolicyAccepted = value;

            if (value)
            {
                this.privacyPolicyShowError = false;
            }
        }
    }
});
